<template>
  <vx-card class="mt-base">
    <div v-if="isEdit">

      <vs-row
        vs-align="flex-start"
        vs-type="flex"
        vs-justify="left"
        vs-w="6"
        style="border: 1px solid rgb(221, 221, 221);margin-top: 15px;margin-bottom: 15px;padding: 0 0 20px 0;border-radius: 10px;"
      >
        <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="12">
          <div class="w-full m-5 mb-0">
            <vs-input
              style="padding-right: 25px !important;"
              :success="!errors.first('MissedCallMessage1')"
              val-icon-success="done"
              val-icon-danger="error"
              :danger="errors.first('MissedCallMessage1') ? true : false"
              v-validate="{
              required: true
            }"
              name="MissedCallMessage1"
              data-vv-as="First Message"
              label="First Message"
              icon-no-border
              icon="icon icon-file-text"
              icon-pack="feather"
              class="w-full"
              :danger-text="errors.first('MissedCallMessage1')"
              v-model="data.MissedCallMessage1"
            />
          </div>
        </vs-col>

        <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="12">
          <div class="w-full m-5 mb-0">
            <vs-input
              style="padding-right: 25px !important;"
              :success="!errors.first('MissedCallMessage2')"
              val-icon-success="done"
              val-icon-danger="error"
              :danger="errors.first('MissedCallMessage2') ? true : false"
              v-validate="{
              required: true
            }"
              name="MissedCallMessage2"
              data-vv-as="Second Message"
              label="Second Message"
              icon-no-border
              icon="icon icon-file-text"
              icon-pack="feather"
              class="w-full"
              :danger-text="errors.first('MissedCallMessage1')"
              v-model="data.MissedCallMessage2"
            />
          </div>
        </vs-col>

        <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="12">
          <div class="w-full m-5 mb-0">
            <vs-input
              style="padding-right: 25px !important;"
              :success="!errors.first('MissedCallMessage3')"
              val-icon-success="done"
              val-icon-danger="error"
              :danger="errors.first('MissedCallMessage3') ? true : false"
              v-validate="{
              required: true
            }"
              name="MissedCallMessage3"
              data-vv-as="Third Message"
              label="Third Message"
              icon-no-border
              icon="icon icon-file-text"
              icon-pack="feather"
              class="w-full padded-input"
              :danger-text="errors.first('MissedCallMessage3')"
              v-model="data.MissedCallMessage3"
            />
          </div>
        </vs-col>

        <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="12">
          <div class="w-full m-5 mb-0">
            <vs-input
              style="padding-right: 25px !important;"
              :success="!errors.first('Time Range')"
              val-icon-success="done"
              val-icon-danger="error"
              :danger="errors.first('MissedCallTimeRange') ? true : false"
              v-validate="{
              required: true
            }"
              name="companyAbn"
              data-vv-as="Time Range (in minutes)"
              label="Time Range (in minutes)"
              icon-no-border
              icon="icon icon-clock"
              icon-pack="feather"
              class="w-full"
              :danger-text="errors.first('MissedCallTimeRange')"
              v-model="data.MissedCallTimeRange"
            />
          </div>
        </vs-col>

      </vs-row>

      <vs-row vs-align="flex-start" vs-type="flex" vs-justify="left" vs-w="12">
        <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
          <div class="w-full ml-5">
            <vs-button
              :disabled="!validateForm"
              @click="saveData"
              icon-pack="feather"
              class="mr-2"
            >Save</vs-button>

            <vs-button color="danger" @click="cancelEdit" icon-pack="feather" class="mr-2">Cancel</vs-button>
          </div>
        </vs-col>
      </vs-row>
    </div>

    <div v-else>
      <vs-row vs-align="flex-start" vs-type="flex" vs-justify="left" vs-w="12">
        <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
          <div class="w-full m-5 mb-0">
            <label for>
              <strong>Doctor Missed Call Settings</strong>
            </label>
          </div>
        </vs-col>
      </vs-row>

      <vs-row
        vs-align="flex-start"
        vs-type="flex"
        vs-justify="left"
        vs-w="6"
        style="border: 1px solid rgb(221, 221, 221);margin-top: 35px;margin-bottom: 15px;padding: 0 0 20px 0;border-radius: 10px;"
      >
        <span
          style="padding: 0px 10px;background: #fff;position: absolute;top: 86px;margin-left: 12px;"
        >Missed Call Settings</span>

        <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="12">
          <div class="w-full m-5 mb-0">
            <label for>
              <strong>First Missed Call Messsage</strong>
            </label>
            <p>{{data.MissedCallMessage1|| "N/A"}}</p>
          </div>
        </vs-col>

        <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="12">
          <div class="w-full m-5 mb-0">
            <label for>
              <strong>Second Missed Call Message</strong>
            </label>
            <p>{{data.MissedCallMessage2|| "N/A"}}</p>
          </div>
        </vs-col>

        <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="12">
          <div class="w-full m-5 mb-0">
            <label for>
              <strong>Third Missed Call Message</strong>
            </label>
            <p>{{data.MissedCallMessage3|| "N/A"}}</p>
          </div>
        </vs-col>

        <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="12">
          <div class="w-full m-5 mb-0">
            <label for>
              <strong>Time Range (in minutes)</strong>
            </label>
            <p>{{data.MissedCallTimeRange|| "N/A"}}</p>
          </div>
        </vs-col>
      </vs-row>

      <vs-row v-if="check_has_permission('updateSettings')" vs-align="flex-start" vs-type="flex" vs-justify="left" vs-w="12">
        <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
          <div class="w-full ml-5">
            <vs-button @click="editData" icon-pack="feather" class="mr-2">Update</vs-button>
          </div>
        </vs-col>
      </vs-row>
    </div>
  </vx-card>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "DoctorMissedCallSettings",
  data() {
    return {
      isEdit: false,
      data: {
        MissedCallMessage1: "",
        MissedCallMessage2: "",
        MissedCallMessage3: "",
        MissedCallTimeRange: "",
      },
    };
  },
  methods: {
    ...mapActions("admin", ["getSettings", "saveSettings"]),
    async getExistingSettings() {
      this.$vs.loading();
      await this.getSettings()
        .then((result) => {
          this.data = result.data.data;
          this.$vs.loading.close();
        })
        .catch((err) => {
          this.$vs.notify({
            title: "Error",
            text: "Error fetching current rate",
            color: "danger",
          });
          this.$vs.loading.close();
        });
    },
    editData() {
      this.isEdit = true;
    },
    async cancelEdit() {
      await this.getExistingSettings();
      this.isEdit = false;
    },
    saveData() {
      this.$vs.loading();
      let saveData = {
        MissedCallMessage1: this.data.MissedCallMessage1,
        MissedCallMessage2: this.data.MissedCallMessage2,
        MissedCallMessage3: this.data.MissedCallMessage3,
        MissedCallTimeRange: this.data.MissedCallTimeRange
      };
      this.saveSettings(saveData)
        .then((result) => {
          this.$vs.notify({
            title: "Success",
            text: "Setting saved successfully",
            color: "success",
          });
          this.$vs.loading.close();
          this.isEdit = false;
        })
        .catch((err) => {
          this.$vs.notify({
            title: "Error",
            text: "Error saving setting",
            color: "danger",
          });
          this.$vs.loading.close();
        });
    },
  },
  computed: {
    validateForm() {
      return !this.errors.any();
    },
  },
  mounted() {},
  async created() {
    await this.getExistingSettings();
  },
};
</script>

<style scoped>
/* .vs-inputx.vs-input--input {
  background: olivedrab !important;
} */
</style>
